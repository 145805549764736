import { useCenterStore } from '@caradasstore/CenterStore'
import { useOrganisationStore } from '@caradasstore/OrganisationStore'
import { SchedularCalendarEvent } from '@components/scheduler/schedule-calendar-event'
import {
  AdasBox,
  AdasButton,
  AdasButtonGroup,
  AdasCalendar,
  AdasDatePicker,
  AdasDrawer,
  AdasTypography,
  AdsCustomModal,
  AdasImage
} from '@components/wrapper-components'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { TextField } from '@mui/material'
import { getDateOverrides, getEvents, getReferralSourceList, getScheduleAvailability } from 'api/api'
import { DATE_FORMATS } from 'constants'
import dayjs from 'dayjs'
import { WeekdaysEnum, weekdaysEnumToFCIndex } from 'enums/enums'
import { Logger } from 'logger'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { getTimeZoneByCode, mapEventsToCalendarFormat, mapOverrideToCalendarFormat } from 'utils/utils'
import { AppointmentDetails } from './AppointmentDetails'
import './SchedulerCalendar.css'
import 'dayjs/locale/en-gb'
import { useDeviceQueries } from '@hooks/useDeviceQueries'
import { daysOfWeekMap } from 'utils/utils'
import eventCountIcon from '../../../assets/images/eventCountIcon.png'

export const ScheduleCalendar = ({ initialView, refresh, setRefresh, openNewEventSlider }) => {
  const calendarRef = useRef(null)
  const ccId = useCenterStore((store) => store.ccId)
  const organisationId = useOrganisationStore((store) => store.organisationId)
  const organisationDetails = useOrganisationStore((state) => state.organisationDetail)
  const [view, setView] = useState(initialView)
  const [title, setTitle] = useState('')
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
  const [isSliderOpen, setIsSliderOpen] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState([])
  const [selectedEventId, setSelectedEventId] = useState(null) // State to hold the selected event ID
  const [calendarHiddenDays, setCalendarHiddenDays] = useState([])
  const [unavailableDates, setUnavailableDates] = useState([])
  const { isMobile, isLandscape } = useDeviceQueries()
  const [businessHours, setBusinessHours] = useState()
  const [eventCount, setEventCount] = useState(0)
  const [referralSourceList, setReferralSourceList] = useState([])

  useEffect(() => {
    const fetchReferralSource = async () => {
      const referralListResponse = await getReferralSourceList(ccId)
      if (referralListResponse.status === 200) {
        const formattedReferralList = referralListResponse.data
          .sort((a, b) => a.company.localeCompare(b.company))
          .map((referralObj) => ({ id: referralObj.code, value: referralObj.company }))
        setReferralSourceList(formattedReferralList)
      }
    }

    fetchReferralSource()
  }, [])

  useEffect(() => {
    setView(isMobile && !isLandscape ? 'timeGridDay' : initialView) // for mobile and portrait then show day grid
    fetchEvents(isMobile && !isLandscape ? 'timeGridDay' : initialView)
  }, [isMobile, isLandscape, initialView])

  const updateTitle = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi()
      setTitle(calendarApi.view.title)
    }
  }

  const handleViewChange = (event, nextView) => {
    if (nextView !== null) {
      setView(nextView) // Fetch events for today
      fetchEvents(nextView)
      updateTitle()
    }
  }

  const handleTodayClick = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi()
      calendarApi.today()
      const today = calendarApi.getDate()
      setSelectedDate(new Date())

      fetchEvents(view, today) // Fetch events for today
      updateTitle()
    }
  }

  const handlePrevClick = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi()

      // Adjust date for timeGridWeek view and update calendar state
      if (view === 'timeGridWeek') {
        calendarApi.prev() // Move the calendar view back by one week
        const newDate = dayjs(selectedDate).subtract(1, 'week').toDate() // Calculate the new date
        setSelectedDate(newDate) // Update the selected date state
        fetchEvents(view, newDate) // Fetch events for the new date
      } else {
        calendarApi.prev() // Move the calendar view back
        const newDate = calendarApi.getDate() // Get the updated date from the calendar
        setSelectedDate(newDate)
        fetchEvents(view, newDate)
      }
      updateTitle()
    }
  }

  const handleNextClick = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi()

      // Adjust date for timeGridWeek view and update calendar state
      if (view === 'timeGridWeek') {
        calendarApi.next() // Move the calendar view forward by one week
        const newDate = dayjs(selectedDate).add(1, 'week').toDate() // Calculate the new date
        setSelectedDate(newDate) // Update the selected date state
        fetchEvents(view, newDate) // Fetch events for the new date
      } else {
        calendarApi.next() // Move the calendar view forward
        const newDate = calendarApi.getDate() // Get the updated date from the calendar
        setSelectedDate(newDate)
        fetchEvents(view, newDate)
      }
      updateTitle()
    }
  }

  useEffect(() => {
    updateTitle()
  }, [selectedDate])

  const handleDateClick = () => {
    setIsDatePickerOpen(true)
  }

  const handleDateChange = (newDate) => {
    if (newDate && dayjs(newDate).isValid()) {
      setSelectedDate(newDate)
      if (calendarRef.current) {
        const calendarApi = calendarRef.current.getApi()
        calendarApi.gotoDate(dayjs(newDate).format(DATE_FORMATS.ISO_DATE))
        const formattedDate = dayjs(newDate).format(DATE_FORMATS.ISO_DATE)
        fetchEvents(view, formattedDate) // Fetch events for the selected date
        updateTitle()
      }

      setIsDatePickerOpen(false)
    }
  }

  const fetchEvents = async (viewMode, date) => {
    let startDate, endDate
    const referenceDate = date || selectedDate
    if (viewMode === 'timeGridDay') {
      startDate = dayjs(referenceDate).startOf('day').utc(false).format(DATE_FORMATS.ISO_DATE)
      endDate = dayjs(referenceDate).endOf('day').utc(false).format(DATE_FORMATS.ISO_DATE)
    } else if (viewMode === 'timeGridWeek') {
      const dayOfWeek = dayjs(referenceDate).day() // 0 (Sunday) to 6 (Saturday)
      const daysToMonday = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek // Calculate days to Monday
      startDate = dayjs(referenceDate).add(daysToMonday, 'day').format(DATE_FORMATS.ISO_DATE) // Start of Monday
      endDate = dayjs(startDate).add(6, 'day').format(DATE_FORMATS.ISO_DATE)
    }

    const params = {
      ccId,
      organisationId,
      startDate,
      endDate
    }

    try {
      const response = await getEvents(params)
      if (response && response.status === 200) {
        const events = response.data
        setSelectedEvent(mapEventsToCalendarFormat(response.data))
        let eventsCount = events?.length || 0
        if (viewMode === 'timeGridDay') {
          eventsCount = events.filter((appointment) => appointment.appointment_date === endDate).length
        }
        setEventCount(eventsCount)

        // setEventCount(events.length)
      }
    } catch (error) {
      console.error('Error fetching events:', error)
      Logger.error({
        message: error,
        payload: { file: 'scheduleCalendar', method: 'fetchEvents' }
      })
    }
  }

  const getFullCalenderHiddenDaysFromAvailability = (fetchedAvailability) => {
    return fetchedAvailability
      .filter((dayObj) => {
        const { start_time, end_time, day } = dayObj
        return (
          start_time[0] === 0 && start_time[1] === 0 && end_time[0] === 0 && end_time[1] === 0 && (day === 'SATURDAY' || day === 'SUNDAY')
        )
      })
      .map((dayObj) => {
        const dayEnumValue = WeekdaysEnum[dayObj.day]
        return weekdaysEnumToFCIndex[dayEnumValue]
      })
  }
  const mapBusinessHours = (data) => {
    return data
      .filter((day) => !(day.start_time[0] === 0 && day.end_time[0] === 0)) // Remove non-working days
      .map((day) => ({
        daysOfWeek: [daysOfWeekMap[day.day]], // Map day name to FullCalendar's daysOfWeek
        startTime: `${day.start_time[0].toString().padStart(2, '0')}:${day.start_time[1].toString().padStart(2, '0')}`, // Format start time
        endTime: `${day.end_time[0].toString().padStart(2, '0')}:${day.end_time[1].toString().padStart(2, '0')}` // Format end time
      }))
  }

  const fetchAndSetFullCalendarHiddenDays = async () => {
    try {
      const response = await getScheduleAvailability({ ccId, organisation_id: organisationId })
      const fetchedAvailability = response?.data
      if (fetchedAvailability && fetchedAvailability.length > 0) {
        setCalendarHiddenDays(getFullCalenderHiddenDaysFromAvailability(fetchedAvailability))
        setBusinessHours(mapBusinessHours(fetchedAvailability))
      }
    } catch (error) {
      Logger.error({
        message: error,
        payload: { file: 'scheduleCalendar', method: 'fetchAndSetFullCalendarHiddenDays' }
      })
    }
  }

  const fetchDateOverrides = async () => {
    try {
      const response = await getDateOverrides({ ccId, organisation_id: organisationId })
      if (response) {
        setUnavailableDates(mapOverrideToCalendarFormat(response.data, getTimeZoneByCode(organisationDetails.timezone_at)))
      }
    } catch (error) {
      Logger.error({
        message: error,
        payload: { file: 'dateoverrides', method: 'fetchDateOverrides' }
      })
    }
  }

  useEffect(() => {
    if (ccId && organisationId) {
      fetchEvents(view)
      fetchAndSetFullCalendarHiddenDays()
      fetchDateOverrides()
    } // Initial fetch on mount
  }, [ccId, organisationId, refresh])

  const handleEventClick = (eventInfo) => {
    if (eventInfo?.event?.extendedProps?.override) return
    if (eventInfo?.event?.id) setSelectedEventId(eventInfo.event.id) // Set the selected event ID
    setIsSliderOpen(true) // Open the slider when an event is clicked
  }

  const isAllowEventSelection = (eventInfo) => {
    const eventDateTime = dayjs(eventInfo.startStr) // Event time in local time
    const startOfCurrentDate = dayjs().startOf('day') // start of the day (00:00)
    const futureLimit = startOfCurrentDate.add(organisationDetails?.max_time, 'minute')
    return eventDateTime.isAfter(startOfCurrentDate) && eventDateTime.isBefore(futureLimit)
  }

  const renderEventContent = (eventInfo) => {
    const { title, extendedProps } = eventInfo.event
    const { id, type, assignee, start, client, offset, vehicle, duration, override } = extendedProps
    // const timeText = dayjs(start).utc(false).format(DATE_FORMATS.ISO_TIME_STRING)
    const timeText = override ? '' : dayjs(start).tz(offset).format(DATE_FORMATS.ISO_TIME_STRING)
    const repair_order_num = (duration === 60 && !assignee?.full_name) || duration >= 90 ? vehicle.repair_order_num : ''
    return (
      <SchedularCalendarEvent
        override={override}
        timeText={timeText}
        type={type}
        title={title}
        id={id}
        assingeeName={assignee?.full_name || ''}
        isActive={assignee?.is_active || ''}
        fullName={client?.business_name || client?.full_name || ''}
        repair_order_num={repair_order_num}
        referralSource={referralSourceList.find((referral) => referral.id === client?.referral_source)?.value}
      />
    )
  }

  const renderHeaderContent = (date) => {
    const currentDate = dayjs(date.date).utc(false) // Pass the ISO date directly
    const today = dayjs().utc(false) // Get today's date
    const isToday = date.isToday // Check if it's today
    const formattedDate = currentDate.format('ddd').toUpperCase() // Get the abbreviated weekday name
    const dayOfMonth = currentDate.format('D') // Get the day of the month

    return (
      <AdasBox className='header-date'>
        <AdasBox className='today-date'>
          <AdasTypography className={`weekday ${isToday ? 'active' : ''}`} variant='body2' component='span'>
            {formattedDate}
          </AdasTypography>
          {isToday ? (
            <AdasBox className='circle'>
              <AdasTypography className='day' variant='h6' component='span'>
                {dayOfMonth}
              </AdasTypography>
            </AdasBox>
          ) : (
            <AdasTypography variant='h6' sx={{ fontWeight: '500' }} component='span'>
              {dayOfMonth}
            </AdasTypography>
          )}
        </AdasBox>
        {isMobile &&
          !isLandscape &&
          (eventCount ? (
            <AdasBox display='flex' alignItems='center'>
              <AdasImage
                src={eventCountIcon}
                alt='holder'
                sx={{
                  height: 'auto',
                  width: '14px',
                  objectFit: 'contain',
                  marginRight: '2px'
                }}
              />
              <AdasTypography
                variant='body2'
                sx={{
                  borderBottom: '1px solid',
                  borderColor: '#888d9a',
                  display: 'inline'
                }}
              >
                <AdasTypography variant='body2' fontWeight='bold' as='span' color={'secondary'}>
                  {eventCount > 99 ? '99+' : eventCount}{' '}
                </AdasTypography>
                <AdasTypography variant='body2' as='span' color={'secondary'}>
                  Event{eventCount > 1 || eventCount === 0 ? 's' : ''}
                </AdasTypography>
              </AdasTypography>
            </AdasBox>
          ) : (
            <AdasTypography variant='body2' color={'secondary'}>
              No Events
            </AdasTypography>
          ))}
      </AdasBox>
    )
  }

  return (
    <>
      <AdasBox
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: { xs: 'start', md: 'space-between' },
          mb: '12px',
          gap: { lg: '24px' }
        }}
      >
        {(!isMobile || isLandscape) && (
          <AdasBox display='flex' gap={2} alignItems='center'>
            <AdasButtonGroup type='toggleButton' value={view} exclusive aria-label='opt-in' onChange={handleViewChange} name='optIn'>
              <AdasButton
                buttonType='toggleButton'
                value='timeGridDay'
                color='primary'
                aria-label='day view'
                sx={{
                  padding: { sm: '5px 10px' },
                  flex: { xs: 1, sm: 'none' }
                }}
              >
                DAY
              </AdasButton>
              <AdasButton
                buttonType='toggleButton'
                value='timeGridWeek'
                color='primary'
                aria-label='week view'
                sx={{
                  padding: { sm: '5px 10px' },
                  flex: { xs: 1, sm: 'none' }
                }}
              >
                WEEK
              </AdasButton>
            </AdasButtonGroup>
            {eventCount ? (
              <AdasBox display='flex' alignItems='center'>
                <AdasImage
                  src={eventCountIcon}
                  alt='holder'
                  sx={{
                    height: 'auto',
                    width: '14px',
                    objectFit: 'contain',
                    marginRight: '2px'
                  }}
                />
                <AdasTypography
                  variant='body2'
                  sx={{
                    borderBottom: '1px solid',
                    borderColor: '#888d9a',
                    display: 'inline'
                  }}
                >
                  <AdasTypography variant='body2' fontWeight='bold' as='span' color={'secondary'}>
                    {eventCount > 99 ? '99+' : eventCount}{' '}
                  </AdasTypography>
                  <AdasTypography variant='body2' as='span' color={'secondary'}>
                    Event{eventCount !== 1 ? 's' : ''}
                  </AdasTypography>
                </AdasTypography>
              </AdasBox>
            ) : (
              <AdasTypography variant='body2' color={'secondary'}>
                No Events
              </AdasTypography>
            )}
          </AdasBox>
        )}
        <AdasBox display='flex' alignItems='center' justifyContent={{ xs: 'center', sm: 'flex-start' }}>
          <AdasButton
            startIcon={<NavigateBeforeIcon />}
            onClick={handlePrevClick}
            aria-label='previous period'
            sx={{
              padding: { xs: '3px', sm: '6px' }
            }}
          />
          <AdasTypography
            variant='h6'
            component='span'
            color='primary'
            onClick={handleDateClick}
            style={{ cursor: 'pointer' }}
            sx={{
              fontSize: { xs: '14px', lg: '18px' },
              mx: { xs: '8px', lg: '12px' }
            }}
          >
            {title}
          </AdasTypography>
          <AdasButton
            startIcon={<NavigateNextIcon />}
            onClick={handleNextClick}
            aria-label='next period'
            sx={{
              padding: { xs: '3px', sm: '6px' }
            }}
          />
        </AdasBox>
        {!isMobile && (
          <AdasButton
            variant='outlined'
            aria-label='TODAY'
            color='primary'
            onClick={handleTodayClick}
            sx={{
              padding: { sm: '6px 12px' }
            }}
          >
            TODAY
          </AdasButton>
        )}
      </AdasBox>

      <AdasCalendar
        view={view}
        height={900}
        SchedularCalendarEvent={SchedularCalendarEvent}
        calendarRef={calendarRef}
        updateTitle={updateTitle}
        setSelectedEvent={setSelectedEvent}
        setIsSliderOpen={setIsSliderOpen}
        setSelectedEventId={setSelectedEventId}
        scrollTime={'08:00'}
        hiddenDays={calendarHiddenDays}
        eventContent={renderEventContent}
        events={[...selectedEvent, ...unavailableDates]}
        nowIndicator={true}
        slotHeight='48px'
        slotMinTime='00:00:00' // Set the minimum time to 8 AM
        slotMaxTime='24:00:00' // Set the maximum time to 5 PM
        allDaySlot={false} // Disable the all-day section
        timeZone={getTimeZoneByCode(organisationDetails.timezone_at)}
        eventClick={handleEventClick}
        selectable={true}
        select={openNewEventSlider}
        firstDay={1}
        selectOverlap={true}
        selectAllow={isAllowEventSelection}
        eventMaxStack={3}
        dayHeaderContent={renderHeaderContent}
        className='adas-calendar'
        businessHours={businessHours}
      />

      <AdsCustomModal title='Select Date' open={isDatePickerOpen} onClose={() => setIsDatePickerOpen(false)}>
        <AdasDatePicker
          label='Select Date'
          variant='outlined'
          name='add_date'
          value={dayjs(selectedDate).format(DATE_FORMATS.ISO_DATE_STRING)}
          renderInput={(params) => <TextField {...params} />}
          format={DATE_FORMATS.ISO_DATE_STRING}
          onChange={handleDateChange}
        />
      </AdsCustomModal>

      {isSliderOpen && (
        <AdasDrawer anchor={'right'} open={isSliderOpen} onClose={() => setIsSliderOpen(false)}>
          <AppointmentDetails cancel={() => setIsSliderOpen(false)} selectedEventId={selectedEventId} setRefresh={setRefresh} />
        </AdasDrawer>
      )}
    </>
  )
}

ScheduleCalendar.propTypes = {
  initialView: PropTypes.oneOf(['timeGridDay', 'timeGridWeek']).isRequired
}
